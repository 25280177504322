<template>
    <div>
        <div class="row">
            <div class="col-md-4" style="display: flex; flex-direction: column;">
                <div class="card card-outline card-primary" style="flex: 1 0 auto;">
                    <div class="card-header">
                        <h3 class="card-title">Rechnung</h3>
                        <span class="float-right" @click="showBillingModal">
                            <i class="fas fa-pen"></i>
                        </span>
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <div class="col-6">
                                <span v-if="order.billing.company != null && order.billing.company != '' || order.billing.department != null && order.billing.department != ''">
                                    <span v-if="order.billing.company != null && order.billing.company != ''">
                                        <b>{{ order.billing.company }}</b><br>
                                    </span>
                                    <span v-if="order.billing.department != null && order.billing.department != ''">
                                        {{ order.billing.department }}<br>
                                    </span>    
                                </span>
                                <span v-if="order.billing.kostenstelle != null && order.billing.kostenstelle != ''">
                                    {{ order.billing.kostenstelle }} <br>
                                </span>
                                {{ order.billing.first_name }} {{ order.billing.last_name }}<br>
                                {{ order.billing.street }} {{ order.billing.house_number }}<br>
                                {{ order.billing.zipcode }} {{  order.billing.city }}
                            </div>
                            <div class="col-6">
                                Telefon: {{ order.billing.phone }} <br/>
                                Ust-Id: {{  order.billing.ustid }} 
                            </div>
                        </div>
                    </div>

                </div>

                <b-modal id="billingModal" title="Rechnung" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="saveBillingDetails">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="" class="col-md-2 col-form-label">Vorname:</label>
                                <div class="col-md-8">
                                    <input type="text" class="form-control form-control-sm" v-model="order.billing.first_name">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="" class="col-md-2 col-form-label">Nachname:</label>
                                <div class="col-md-8">
                                    <input type="text" class="form-control form-control-sm" v-model="order.billing.last_name">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="" class="col-md-2 col-form-label">Firma:</label>
                                <div class="col-md-8">
                                    <input type="text" class="form-control form-control-sm" v-model="order.billing.company">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="" class="col-md-2 col-form-label">Abteilung:</label>
                                <div class="col-md-8">
                                    <input type="text" class="form-control form-control-sm" v-model="order.billing.department">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="" class="col-md-2 col-form-label">UstId:</label>
                                <div class="col-md-8">
                                    <input type="text" class="form-control form-control-sm" v-model="order.billing.ustid">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="" class="col-md-2 col-form-label">Kostenstelle:</label>
                                <div class="col-md-8">
                                    <input type="text" class="form-control form-control-sm" v-model="order.billing.kostenstelle">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="" class="col-md-2 col-form-label">Telefon:</label>
                                <div class="col-md-8">
                                    <input type="text" class="form-control form-control-sm" v-model="order.billing.phone">
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="" class="col-md-2 col-form-label">Straße:</label>
                                <div class="col-md-10">
                                    <input type="text" class="form-control form-control-sm" v-model="order.billing.street">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="" class="col-md-2 col-form-label">Hausnummer:</label>
                                <div class="col-md-10">
                                    <input type="text" class="form-control form-control-sm" v-model="order.billing.house_number">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="" class="col-md-2 col-form-label">Postleitzahl:</label>
                                <div class="col-md-10">
                                    <input type="text" class="form-control form-control-sm" v-model="order.billing.zipcode">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="" class="col-md-2 col-form-label">Stadt:</label>
                                <div class="col-md-10">
                                    <input type="text" class="form-control form-control-sm" v-model="order.billing.city">
                                </div>
                            </div>
                        </div>
                    </div>
                </b-modal>

            </div>
            <div class="col-md-4" style="display: flex; flex-direction: column;">
                <div class="card card-outline card-primary" style="flex: 1 0 auto;">
                    <div class="card-header">
                        <h3 class="card-title">Versand</h3>
                        <span class="float-right" @click="showShippingModal">
                            <i class="fas fa-pen"></i>
                        </span>
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <div class="col-6">
                                <span v-if="order.shipping.company != null && order.shipping.company != '' || order.shipping.department != null && order.shipping.department != ''">
                                    <span v-if="order.shipping.company != null && order.shipping.company != ''">
                                        <b>{{ order.shipping.company }}</b><br>
                                    </span>
                                    <span v-if="order.shipping.department != null && order.shipping.department != ''">
                                        {{ order.shipping.department }}<br>
                                    </span>    
                                </span>
                                <span v-if="order.shipping.kostenstelle != null && order.shipping.kostenstelle != ''">
                                    {{ order.shipping.kostenstelle }} <br>
                                </span>
                                {{ order.shipping.first_name }} {{ order.shipping.last_name }}<br>
                                <template v-if="order.shipping.type == 'address'">
                                    {{ order.shipping.street }} {{ order.shipping.house_number }} <br>
                                </template>
                                <template v-if="order.shipping.type == 'packstation'">
                                    {{ order.shipping.post_customer_number }} <br>
                                    Packstation {{ order.shipping.post_number }} <br>
                                </template>
                                <template v-if="order.shipping.type == 'postfiliale'">
                                    {{ order.shipping.post_customer_number }} <br>
                                    Postfiliale {{ order.shipping.post_number }} <br>
                                </template>
                                {{ order.shipping.zipcode }} {{  order.shipping.city }}
                            </div>
                            <div class="col-6">
                                Telefon: {{ order.shipping.phone }}
                            </div>
                        </div>
                    </div>

                </div>

                <b-modal id="shippingModal" title="Lieferung" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="saveShippingDetails">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="company" class="control-label col-sm-2 col-form-label col-form-label-sm">Typ</label>
                                <div class="col-sm-8">
                                    <select class="form-control form-control-sm" v-model="order.shipping.type">
                                        <option value="address">Adresse</option>
                                        <option value="packstation">Packstation</option>
                                        <option value="postfiliale">Postfiliale</option>
                                    </select>
                                    <!-- <has-error :form="form" field="type"></has-error> -->
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="" class="col-md-2 col-form-label">Vorname:</label>
                                <div class="col-md-8">
                                    <input type="text" class="form-control form-control-sm" v-model="order.shipping.first_name">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="" class="col-md-2 col-form-label">Nachname:</label>
                                <div class="col-md-8">
                                    <input type="text" class="form-control form-control-sm" v-model="order.shipping.last_name">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="" class="col-md-2 col-form-label">Firma:</label>
                                <div class="col-md-8">
                                    <input type="text" class="form-control form-control-sm" v-model="order.shipping.company">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="" class="col-md-2 col-form-label">Abteilung:</label>
                                <div class="col-md-8">
                                    <input type="text" class="form-control form-control-sm" v-model="order.shipping.department">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="" class="col-md-2 col-form-label">Kostenstelle:</label>
                                <div class="col-md-8">
                                    <input type="text" class="form-control form-control-sm" v-model="order.shipping.kostenstelle">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="" class="col-md-2 col-form-label">Telefon:</label>
                                <div class="col-md-8">
                                    <input type="text" class="form-control form-control-sm" v-model="order.shipping.phone">
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <template v-if="order.shipping.type == 'address'">
                                <div class="form-group row">
                                    <label for="" class="col-md-2 col-form-label">Straße:</label>
                                    <div class="col-md-10">
                                        <input type="text" class="form-control form-control-sm" v-model="order.shipping.street">
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="" class="col-md-2 col-form-label">Hausnummer:</label>
                                    <div class="col-md-10">
                                        <input type="text" class="form-control form-control-sm" v-model="order.shipping.house_number">
                                    </div>
                                </div>
                            </template>

                            <template v-if="order.shipping.type == 'packstation'">
                                <div class="form-group row">
                                    <label for="" class="col-md-2 col-form-label">DHL Kd-Nr.:</label>
                                    <div class="col-md-10">
                                        <input type="text" class="form-control form-control-sm" v-model="order.shipping.post_customer_number">
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="" class="col-md-2 col-form-label">Paketstation:</label>
                                    <div class="col-md-10">
                                        <input type="text" class="form-control form-control-sm" v-model="order.shipping.post_number">
                                    </div>
                                </div>
                            </template>

                            <template v-if="order.shipping.type == 'postfiliale'">
                                <div class="form-group row">
                                    <label for="" class="col-md-2 col-form-label">DHL Kd-Nr.:</label>
                                    <div class="col-md-10">
                                        <input type="text" class="form-control form-control-sm" v-model="order.shipping.post_customer_number">
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="" class="col-md-2 col-form-label">Postfiliale:</label>
                                    <div class="col-md-10">
                                        <input type="text" class="form-control form-control-sm" v-model="order.shipping.post_number">
                                    </div>
                                </div>
                            </template>
                            <div class="form-group row">
                                <label for="" class="col-md-2 col-form-label">Postleitzahl:</label>
                                <div class="col-md-10">
                                    <input type="text" class="form-control form-control-sm" v-model="order.shipping.zipcode">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="" class="col-md-2 col-form-label">Stadt:</label>
                                <div class="col-md-10">
                                    <input type="text" class="form-control form-control-sm" v-model="order.shipping.city">
                                </div>
                            </div>
                        </div>
                    </div>
                </b-modal>
            </div>
            <div class="col-md-2" style="display: flex; flex-direction: column;">
                <div class="card card-outline card-primary" style="flex: 1 0 auto;">
                    <div class="card-header">
                        <h3 class="card-title">Zahlungsarten</h3>
                        <span class="float-right" @click="showPaymentModal">
                            <i class="fas fa-pen"></i>
                        </span>
                    </div>

                    <div class="card-body">
                        {{ order.payment.description }}
                    </div>

                </div>
                <b-modal id="paymentModal" title="Zahlungsart" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="saveOrderDetails">
                    <div class="form-group row">
                        <label for="" class="col-md-2 col-form-label">Zahlung:</label>
                        <div class="col-md-10">
                            <select class="form-control form-control-sm" v-model="order.payment.id">
                                <option v-for="payment in payments" :key="payment.id" :value="payment.id">{{ payment.description }}</option>
                            </select>
                        </div>
                    </div>
                </b-modal>
            </div>
            <div class="col-md-2" style="display: flex; flex-direction: column;">
                <div class="card card-outline card-primary" style="flex: 1 0 auto;">
                    <div class="card-header">
                        <h3 class="card-title">Versandart</h3>
                        <span class="float-right" @click="showDispatchModal">
                            <i class="fas fa-pen"></i>
                        </span>
                    </div>

                    <div class="card-body">
                        {{ order.dispatch.name }}
                    </div>

                </div>
                <b-modal id="dispatchModal" title="Versandart" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="saveOrderDetails">
                    <div class="form-group row">
                        <label for="" class="col-md-2 col-form-label">Versand:</label>
                        <div class="col-md-10">
                            <!-- <select class="form-control form-control-sm" v-model="order.dispatch.id">
                                <option v-for="dispatch in dispatches" :key="dispatch.id" :value="dispatch.id">{{ dispatch.name }}</option>
                            </select> -->
                            <select v-model="order.dispatch.id" class="form-control form-control-sm">
                            <optgroup v-for="company in companies" :key="company.id" :label="company.name">
                                <option v-for="dispatch in company.dispatches" :key="dispatch.id" :value="dispatch.id">{{ dispatch.name }}</option>
                            </optgroup>
                        </select>
                        </div>
                    </div>
                </b-modal>
            </div>
        </div>

        <div class="row">
            <div class="col-md-8" style="display: flex; flex-direction: column;">
                <div class="card card-outline card-primary" style="flex: 1 0 auto;">
                    <div class="card-header">
                        <h3 class="card-title">Bestellung-Details</h3>
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="mb-1 row">
                                    <div class="col-md-4">
                                        <strong>Shop:</strong>
                                    </div>
                                    <div class="col-md-8">
                                        {{ order.company.name }}
                                    </div>
                                </div>

                                <div class="mb-1 row">
                                    <div class="col-md-4">
                                        <strong>Bestellzeit:</strong>
                                    </div>
                                    <div class="col-md-8">
                                        {{ order.orderdate }}
                                    </div>
                                </div>

                                <div class="mb-1 row">
                                    <div class="col-md-4">
                                        <strong>Bestellnummer:</strong>
                                    </div>
                                    <div class="col-md-8">
                                        {{ order.ordernumber }}
                                    </div>
                                </div>

                                <div class="mb-1 row">
                                    <div class="col-md-4">
                                        <strong>Gesamtbetrag:</strong>
                                    </div>
                                    <div class="col-md-8">
                                        {{ order.invoice_amount | toCurrency }}
                                    </div>
                                </div>

                                <div class="mb-1 row text-danger" v-if="order.total_stornos != 0">
                                    <div class="col-md-4">
                                        <strong>Stornos:</strong>
                                    </div>
                                    <div class="col-md-8">
                                        {{ order.total_stornos | toCurrency }}
                                    </div>
                                </div>

                                <div class="mb-1 row text-primary">
                                    <div class="col-md-4">
                                        <strong>Zahlbetrag:</strong>
                                    </div>
                                    <div class="col-md-8">
                                        {{ order.invoice_amount + order.total_stornos | toCurrency }}
                                    </div>
                                </div>

                                <div class="mb-1 row">
                                    <div class="col-md-4">
                                        <strong>Gewählte Versandart:</strong>
                                    </div>
                                    <div class="col-md-8">
                                        {{ order.dispatch.name }} 
                                        <span v-if="order.dispatch.prio_external == 0">
                                            <span v-if="order.prioExternal == 1">(PRIO)</span>
                                        </span>
                                    </div>
                                </div>

                                <div class="mb-1 row">
                                    <div class="col-md-4">
                                        <strong>Email:</strong>
                                    </div>
                                    <div class="col-md-8">
                                        <a :href="'mailto:' + order.customer.email">{{ order.customer.email }}</a>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="mb-1 row">
                                    <div class="col-md-5">
                                        <strong>Wunschlieferdatum:</strong>
                                    </div>
                                    <div class="col-md-7">
                                        <div v-if="selectedCell === 'wishdate'" class="input-group">
                                            <input type="date" class="form-control form-control-sm" v-model="order.wishdate" v-if="$auth.check('orders.edit')" />
                                            <div class="input-group-append">
                                                <button class="btn btn-sm btn-primary ml-1 mr-1" type="button" @click="changeWishdate"><i class="fas fa-save"></i></button>
                                                <button class="btn btn-sm btn-secondary" type="button" @click="closeEditCell()"><i class="fas fa-times"></i></button>
                                            </div>
                                        </div>
                                        <template v-else>
                                            <span @dblclick="handleEditCell('wishdate')" v-if="order.wishdate == null || order.store_id != null">-- Nicht vorhanden --</span>
                                            <span @dblclick="handleEditCell('wishdate')" v-else><strong>{{ order.wishdate | formatDate }}</strong></span>
                                        </template>
                                        
                                    </div>
                                </div>

                                <div class="mb-1 row">
                                    <div class="col-md-5">
                                        <strong>Abstellhinweis:</strong>
                                    </div>
                                    <div class="col-md-7">
                                        <span v-if="order.delivery_note == null || order.delivery_note == 'none'">-- Nicht vorhanden --</span>
                                        <span v-else><strong>{{ order.delivery_note }}</strong></span>
                                    </div>
                                </div>

                                <div class="mb-1 row">
                                    <div class="col-md-5">
                                        <strong>Alternativartikel:</strong>
                                    </div>
                                    <div class="col-md-7">
                                        <span v-if="order.alternative==0 || order.alternative == null" style="color: red"><strong>KEINE ZUSTIMMUNG</strong></span>
                                        <span v-else style="color: green"><strong>ERLAUBT</strong></span>
                                    </div>
                                </div>

                                <div class="mb-1 row">
                                    <div class="col-md-5">
                                        <strong>Abholfiliale:</strong>
                                    </div>
                                    <div class="col-md-7">
                                        <span v-if="order.store ==0 || order.store == null">-- Nicht vorhanden --</span>
                                        <span v-else><strong>{{ order.store.name }}</strong></span>
                                    </div>
                                </div>

                                <div class="mb-1 row">
                                    <div class="col-md-5">
                                        <strong>Abholzeitpunkt:</strong>
                                    </div>
                                    <div class="col-md-7">
                                        <span v-if="order.wishdate == null || order.store_id == null">-- Nicht vorhanden --</span>
                                        <span v-else><strong>{{ order.wishdate | formatDate }} / {{ order.wishtime }} Uhr</strong></span>
                                    </div>
                                </div>
                                
                                <div class="mb-1 row">
                                    <div class="col-md-5">
                                        <strong>User-Agent:</strong>
                                    </div>
                                    <div class="col-md-7">
                                        <span>{{ order.useragent }}</span>
                                    </div>
                                </div>

                                <div class="mt-3 mb-1 row align-items-center">
                                    <div class="col-md-5">
                                        <strong>Kunde:</strong>
                                    </div>
                                    <div class="col-md-7">
                                        <router-link class="btn btn-primary btn-sm" :to="{name: 'customers.details', params: {id: order.customer.id}}" v-if="$auth.check('customers.show')"><i class="fas fa-user"></i> Kunde öffnen</router-link>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success mt-2">
                                        <input type="checkbox" class="custom-control-input" id="customSwitchShowCustomer" v-model="order.show_customer">
                                        <label class="custom-control-label" for="customSwitchShowCustomer">Bestellung im Kundenkonto anzeigen</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-md-4" style="display: flex; flex-direction: column;">
                <div class="card card-primary card-outline" style="flex: 1 0 auto;">
                    <div class="card-header">
                        <h3 class="card-title">Eingelöste Gutscheine/Coupons</h3>
                    </div>
                    <div class="card-body">
                        <template v-if="order.vouchers.length <= 0 && order.friendCode == null" >
                            <div>
                                <h6><strong>Es wurden keine Gutscheine eingelöst</strong></h6>
                            </div>
                        </template>

                        <template v-if="order.vouchers.length > 0 || order.friendCode != null">
                            <table class="table table-striped">
                                <thead>
                                    <th>#</th>
                                    <th>Art</th>
                                    <th>Code</th>
                                    <th>Name</th>
                                    <!-- <th>Betrag</th> -->
                                </thead>
                                <tbody>
                                    <tr v-for="(voucher,index) in order.vouchers" :key="voucher.id">
                                        <td>{{ index + 1 }}</td>
                                        <td>
                                            <template v-if="voucher.type == 'voucher'">Gutschein</template>
                                            <template v-if="voucher.type == 'coupon'">Coupon</template>
                                        </td>
                                        <td>{{ voucher.code }}</td>
                                        <td>{{ voucher.title }}</td>
                                    </tr>
                                    <tr v-if="order.friendCode.share_code != null">
                                        <td>{{order.vouchers.length + 1}}</td>
                                        <td>Freundschaftscode</td>
                                        <td>{{order.friendCode.share_code}}</td>
                                        <td>{{order.company.shared_title}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="card card-outline card-primary">
                    <div class="card-header">
                        <h3 class="card-title">Bestellung bearbeiten</h3>
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group row">
                                    <label for="" class="col-md-3 col-form-label">Bezahlt am:</label>
                                    <div class="col-md-7">
                                        <input type="date" class="form-control form-control-sm" disabled v-model="order.cleared"/>
                                    </div>
                                </div>

                                <!-- <div class="form-group row">
                                    <label for="" class="col-md-2 col-form-label">Trackingcode:</label>
                                    <div class="col-md-8">
                                        <input type="text" class="form-control form-control-sm" />
                                    </div>
                                </div> -->

                                <div class="form-group row align-items-center">
                                    <label for="" class="col-md-3 col-form-label">Versandkosten:</label>
                                    <div class="col-md-7">
                                        <!-- <input type="number" class="form-control form-control-sm" v-model="order.invoice_shipping" /> -->
                                        <span>{{ order.invoice_shipping | toCurrency }}</span>
                                    </div>
                                </div>

                                <div class="form-group row align-items-center">
                                    <label for="" class="col-md-3 col-form-label">Versandkosten Steuersatz:</label>
                                    <div class="col-md-7">
                                        <!-- <input type="number" class="form-control form-control-sm" v-model="order.invoice_shipping_tax_rate" /> -->
                                        <span>{{ order.invoice_shipping_tax_rate }} %</span>
                                    </div>
                                </div>

                            </div>

                            <div class="col-md-6">
                                <div class="form-group row">
                                    <label for="" class="col-md-3 col-form-label">Bestellstatus:</label>
                                    <div class="col-md-7">
                                        <select class="form-control form-control-sm" v-model="order.order_status">
                                            <option v-for="state in orderStates" :key="state.id" :value="state.id">{{ state.description }}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="" class="col-md-3 col-form-label">Zahlungsstatus:</label>
                                    <div class="col-md-7">
                                        <select class="form-control form-control-sm" v-model="order.billing_status">
                                            <option v-for="state in billingStates" :key="state.id" :value="state.id">{{ state.description }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <button class="float-right btn btn-primary" @click="saveOrder">Speichern</button>
            </div>
        </div>


        <b-modal :no-enforce-focus="true" id="mailOrderModal" title="E-Mail an den Kunden senden" ok-variant="primary" ok-title="E-Mail senden" cancel-title="Abbrechen" size="xl" @ok="sendNotification">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="">An:</label>
                        <select class="form-control form-control-sm" multiple v-model="orderModal.selected_emails">
                            <option v-for="email in orderModal.emails" :key="email" :value="email">{{ email }}</option>
                        </select>
                    </div>

                    <div class="form-group">
                        <label for="">Betreff:</label>
                        <input type="text" class="form-control form-control-sm" v-model="orderModal.subject"/>
                    </div>

                    <div class="form-group">
                        <label for="">Anhänge:</label>
                        <select class="form-control form-control-sm" multiple v-model="orderModal.attachments">
                            <option v-for="document in order.documents" :key ="document.id" :value="document.id">{{ document.document.name + '-' + document.number }}</option>
                        </select>
                    </div>

                    <div class="form-group">
                        <editor
                                v-bind:api-key="this.$tinyApiKey"
                                :init="{
                                    height: 400,
                                    menubar: true,
                                    plugins: [
                                    'code advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar:
                                    'undo redo | formatselect | bold italic backcolor | \
                                    alignleft aligncenter alignright alignjustify | \
                                    bullist numlist outdent indent | removeformat | code | help'
                                }"
                                v-model="orderModal.message"
                              
                                />
                    </div>
                </div>
            </div>

            <template #modal-footer="{ ok, cancel}">
                <b-button size="sm" variant="light" @click="cancel()">
                    Abbrechen
                </b-button>
                <b-button size="sm" variant="primary" @click="ok()" :disabled="orderModal.selected_emails.length <= 0">
                    Senden
                </b-button>
            </template>
        </b-modal>

  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
// import ClassicEditor from '../../../assets/packages/ckeditor5/src/ckeditor';
import "tinymce/tinymce";
// import "./../../../assets/packages/tinymce-bootstrap";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/skins/ui/oxide/skin.css";
import "tinymce/plugins/advlist";
import "tinymce/plugins/autolink";
import "tinymce/plugins/bootstrap";
import "tinymce/plugins/code";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/lists";
import "tinymce/plugins/charmap";
import "tinymce/plugins/print";
import "tinymce/plugins/preview";
import "tinymce/plugins/help";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/table";

	
export default {
    name: 'OrderDetailsOverview',
    props: [
        'order'
    ],
    data(){
        return  {
            companies: [],
            payments: [],
            dispatches: [],
            editor: Editor,
            editorConfig: {
                simpleUpload: {
                    uploadUrl: process.env.VUE_APP_API_URL + 'images/upload',
                    withCredentials: true,
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer '+ this.$auth.token()
                    }
                }
            },
            orderStates: [],
            billingStates: [],
            oldOrderState: null,
            oldBillingState: null,
            test: '',
            selectedCell: '',
            orderModal: {
                message: '',
                subject: '',
                emails: [],
                selected_emails: [],
                company_id: '',
                attachments: [],
            },
            newState: {
                template:{
                    subject: '',
                }              
            },
            options: {
                replaceable: {
                    '{first_name}': this.order.customer.first_name,
                    '{company_name}': this.order.company.name,
                    '{tracking_code}': '',
                    '{ordernumber}': this.order.ordernumber,
                    '{orderdate}': this.order.orderdate,
                    '{invoicenumber}': this.order.invoice != null ? this.order.invoice.number : '',      
                }
            }
        }
    },

    methods:{
        changeWishdate(){
            // console.log('bla');
            if(this.wishdate == ''){
                this.wishdate = null;
            }
            this.axios
            .put("/orders/" + this.order.id, {
                'wishdate': this.order.wishdate,
            })
            .then(() => {
                // this.billingStates = response.data.data;
                this.closeEditCell();
                this.$parent.getOrder();
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
                });
            });
        },

        closeEditCell(){
            this.selectedCell = '';
        },

        handleEditCell(name) {
            this.selectedCell = name;
        },

        showBillingModal(){
            this.$bvModal.show("billingModal");
        },

        showShippingModal(){
            this.$bvModal.show("shippingModal");
        },

        showPaymentModal(){
            this.$bvModal.show("paymentModal");
        },

        showDispatchModal(){
            this.$bvModal.show("dispatchModal");
        },

        sendNotification(bvModalEvt){
            bvModalEvt.preventDefault();
            this.$Progress.start();
            this.axios
            .post("/mail/send", {
                'emails': this.orderModal.selected_emails,
                'subject': this.orderModal.subject,
                'content': this.orderModal.message,
                'company_id': this.orderModal.company_id,
                'documents': this.orderModal.attachments,
            })
            .then(() => {
            this.$Progress.finish();
            this.orderModal.attachments = [];
            this.orderModal.selected_emails = [];
            this.$bvModal.hide('mailOrderModal');
            this.$swal({
                icon: "success",
                title: "E-Mail gesendet",
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                });
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufenn",
                });
                this.$Progress.fail();
            });
        },

        sendMail(order, status){
            //console.log(status.name);
            this.$Progress.start();
            this.axios
            .post("/orders/mail/send", {
                'order_id': order.id,
                'company_id': order.company.id,
                'type': 'status',
                'template': status.name,
                'group': status.group
            })
            .then((response) => {
                this.$Progress.finish();
                this.orderModal.message = response.data.data;
                this.orderModal.subject = status.subject;
                this.orderModal.emails = [];
                this.orderModal.emails.push(order.customer.email);
                this.orderModal.selected_emails.push(order.customer.email);
                // if(order.customer.invoice_email != null){
                //     this.orderModal.emails.push(order.customer.invoice_email);
                // }
                this.orderModal.company_id = order.company.id;
                this.$bvModal.show('mailOrderModal');
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
                });
                this.$Progress.fail();
            });
        },

        saveBillingDetails(){
            this.axios
            .put("/orders/" + this.order.id, {
                'billing': this.order.billing,
            })
            .then(() => {
                // this.billingStates = response.data.data;
                this.$parent.getOrder();
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
                });
            });
        },

        saveShippingDetails(){
        this.axios
            .put("/orders/" + this.order.id, {
                'shipping': this.order.shipping,
            })
            .then(() => {
                // this.billingStates = response.data.data;
                this.$parent.getOrder();
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
                });
            });
        },

        saveOrderDetails(){
        this.axios
            .put("/orders/" + this.order.id, {
                'billing': this.order.billing,
                'shipping': this.order.shipping,
                'dispatch_id': this.order.dispatch.id,
                'payment_id': this.order.payment.id, 
                'show_customer': this.order.show_customer, 
            })
            .then(() => {
                // this.billingStates = response.data.data;
                this.$parent.getOrder();
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
                });
            });
        },
        
        saveOrder(){
            this.axios
                .put("/orders/" + this.order.id, {
                    'trackingcode': this.order.trackingcode,
                    //'cleared': this.order.cleared,
                    'invoice_shipping': this.order.invoice_shipping,
                    'invoice_shipping_tax_rate': this.order.invoice_shipping_tax_rate,
                    'order_status': this.order.order_status,
                    'billing_status': this.order.billing_status,
                    'show_customer': this.order.show_customer, 
                })
                .then(() => {
                    // this.billingStates = response.data.data;
                    this.$swal({
                        icon: "success",
                        title: "Bestelldetails wurden gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                        });
                    this.$parent.getOrder();

                    if(this.oldOrderState != this.order.order_status)
                    {
                        //Status hat sich geändert. Prüfe nun ob eine Mail vesendet werden muss
                        this.newState = this.orderStates.find( data => data.id === this.order.order_status );

                        if(this.newState.template == null)
                        {
                            this.newState.template = {
                                'subject': '',
                            }
                        }

                        if(this.newState.mail == 1)
                        // Es muss eine Mail versendet werden
                        {
                            // const input = this.replaceVariables(this.newState.template.content || '')
                            // this.test = input;
                            // //Es muss eine Mail gesendet werden
                            if(this.order.rsu == null){
                                this.sendMail(this.order, this.newState);
                            }
                            //this.$bvModal.show("mailOrderModal");
                        }

                        this.oldOrderState = this.order.order_status;
                    }

                    if(this.oldBillingState != this.order.billing_status)
                    {
                        //Status hat sich geändert. Prüfe nun ob eine Mail vesendet werden muss
                        this.newState = this.billingStates.find( data => data.id === this.order.billing_status );

                        if(this.newState.template == null)
                        {
                            this.newState.template = {
                                'subject': '',
                            }
                        }

                        if(this.newState.mail == 1)
                        {
                            // const input = this.replaceVariables(this.newState.template.content || '')
                            // this.test = input;
                            // //Es muss eine Mail gesendet werden
                            // this.$bvModal.show("mailModal");
                            if(this.order.rsu == null){
                                this.sendMail(this.order, this.newState);
                            }
                            
                        }

                        this.oldBillingState = this.order.billing_status;
                    }

                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        replaceVariables(input) {
			let lookup = this.options.replaceable || {}
			let updated = input
			Object.keys(lookup).forEach((key) => {
				updated = updated.replace(new RegExp(key, 'g'), lookup[key])
			})
			return updated
		},

        getOrderStates(){
            this.$Progress.start();
            this.axios
                .get("/states/order")
                .then((response) => {
                    this.orderStates = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        getBillingStates(){
            this.$Progress.start();
            this.axios
                .get("/states/billing")
                .then((response) => {
                    this.billingStates = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        getCompanies(){
            this.$Progress.start();
            this.axios
                .get("/companies")
                .then((response) => {
                    this.companies = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        getPayments(){
            this.$Progress.start();
            this.axios
                .get("/payments")
                .then((response) => {
                    this.payments = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        getDispatches(){
            this.$Progress.start();
            this.axios
                .get("/dispatches")
                .then((response) => {
                    this.dispatches = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },
    }, 

        created() {
            this.getCompanies();
            this.getPayments();
            this.getDispatches();
            this.getOrderStates();
            this.getBillingStates();
            this.oldOrderState = this.order.order_status;
            this.oldBillingState = this.order.billing_status;
        }

}
</script>

<style>

</style>